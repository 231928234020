import { colors } from '@/styles/GlobalStyle';
import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  max-width: 500px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;

  min-height: 80vh;

  h2 {
    font-family: 'Exo 2';
    font-weight: 400;
    font-size: 34px;
    line-height: 41px;
    color: ${colors.gray700};
    margin-top: 20px;
  }

  p {
    font-size: 16px;
    line-height: 28px;
    color: ${colors.gray700};
    font-weight: 400;
    font-family: 'Sora';
    max-width: 360px;
    display: inline-block;

    margin-top: 20px;
  }

  a {
    color: ${colors.orange300};
    display: inline;

    :hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 1440px) {
    padding: 0 40px;
  }

  @media (max-width: 499px) {
    padding: 0 16px;
  }
`;
