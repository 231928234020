import Layout from '@/components/global/Layout';
import SEO from '@/components/global/SEO';
import PageSection from '@/components/Pages/404/PageSection';

import { PageWrapper, PageContainer, SectionWrapper } from '@/styles/Page';

const Page404 = (): JSX.Element => {
  return (
    <>
      <SEO title="Página não encontrada" description="Página não encontrada" />
      <Layout>
        <PageWrapper>
          <PageContainer>
            <SectionWrapper>
              <PageSection />
            </SectionWrapper>
          </PageContainer>
        </PageWrapper>
      </Layout>
    </>
  );
};

export default Page404;
