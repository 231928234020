import Image from 'next/image';
import { Container } from './styles';
import Link from 'next/link';

import SectionImage from '@/images/Pages/404/section-image.png';

const PageSection = (): JSX.Element => {
  return (
    <Container>
      <Image
        src={SectionImage}
        alt="Imagem de uma caixa amassada"
        quality={100}
        placeholder="blur"
      />
      <div>
        <h2>Esta página não foi encontrada</h2>
        <p>
          Talvez você queira acessar nossa página de{' '}
          <Link href="/rastreamento" passHref>
            <a>Serviços online </a>
          </Link>
          ou a
          <Link href="/" passHref>
            <a> Página inicial</a>
          </Link>
          .
        </p>
      </div>
    </Container>
  );
};

export default PageSection;
